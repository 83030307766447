/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, Link, Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import dayjs from "dayjs";
import { amountFormatter } from "../../constants";
import styles from "./rtp-details.module.scss";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { useSnackbar } from "notistack";
import { apiRequest } from "../../api/services/Api";

const RTPDetails = () => {
  const { t } = useTranslation();
  const { rtpId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [rtpInfo, setRtpInfo] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchRTPDetails = async () => {
      setIsFetching(true);
      try {
        const { status, response } = await apiRequest(
          "get",
          `/cap/api/v1/rtp/${rtpId}`,
          null
        );

        if (status === 200) {
          const data = response.data;
          setRtpInfo(data);
        } else if (status === 401 || status === 403) {
          enqueueSnackbar(t("Error_unauthorized"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Not_found"), { variant: "error" });
          setRedirect(true);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        } else {
          enqueueSnackbar(t("Unknow_error"), { variant: "error" });
        }
      } catch (error) {
        console.error("Failed to fetch RTP details:", error);
        enqueueSnackbar(t("Unknow_error"), { variant: "error" });
      } finally {
        setIsFetching(false);
      }
    };

    fetchRTPDetails();
  }, [rtpId]);

  const navBarTitle = `${t("Request_to_pay")} #${rtpId}`;

  const breadcrumbs = {
    step1Label: t("Request_to_pay"),
    step1Url: "/app/requests",
    step2Label: `RTP #${rtpId}`,
  };

  if (redirect) {
    return <Redirect to="/app/requests" />;
  }

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }}>
      <div>
        {isFetching ? (
          <div className={styles.center_position}>
            <CircularProgress className="circular-progress" size={60} />
          </div>
        ) : (
          <>
            <div className={styles.info_block}>
              <div className={styles.info_block_title}>
                <span>{t("Details_request_payment")}</span>
              </div>
              <div>
                {/* Displayed data from the API response */}
                <div className={styles.info_block_input_container}>
                  <label>{t("ID")}</label>
                  <div className={styles.block_value}>{rtpInfo.id}</div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("External_request_ID")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.externalId || "-"}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Request_creation_date")}</label>
                  <div className={styles.block_value}>
                    {dayjs(rtpInfo.createdDate).format("DD/MM/YYYY, HH:mm")}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Last_update_date")}</label>
                  <div className={styles.block_value}>
                    {dayjs(rtpInfo.lastUpdatedDate).format("DD/MM/YYYY, HH:mm")}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Request_source")}</label>
                  <div className={styles.block_value}>{rtpInfo.source}</div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("File_import_flag")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.fileImported ? t("Yes") : t("No")}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Payment_result_ID")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.paymentResultId || "-"}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Ecomm_payment_ID")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.ecommPaymentId || "-"}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Error_code")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.errorCode || "-"}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Error_message")}</label>
                  <div className={styles.block_value} style={{ maxHeight: "300px", overflowY: "scroll"}}>
                    {rtpInfo.errorMessage || "-"}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("POS_ID")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.posId ? (
                      <Link
                        to={`/app/terminals/${rtpInfo.posId}`}
                        className={styles.no_underline}
                      >
                        POS-{rtpInfo.posId}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Transaction_id")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.transactionId ? (
                      <Link
                        to={`/app/transactions/${rtpInfo.transactionId}`}
                        className={styles.no_underline}
                      >
                        TX-{rtpInfo.transactionId}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Client_phone_number")}</label>
                  <div className={styles.block_value}>{rtpInfo.phoneNumber}</div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Payment_description")}</label>
                  <div className={styles.block_value}>{rtpInfo.paymentDescription}</div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Payment_amount")}</label>
                  <div className={styles.block_value}>
                    {amountFormatter(rtpInfo.amount)}
                    <span className={styles.uppercase}>{rtpInfo.ccy}</span>
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Request_status")}</label>
                  <div className={styles.block_value}>
                    {UseErrorLabel(rtpInfo.status, t)}
                  </div>
                </div>

                <div className={styles.info_block_input_container}>
                  <label>{t("Request_expiry_date")}</label>
                  <div className={styles.block_value}>
                    {rtpInfo.expiryDate ? dayjs(rtpInfo.expiryDate).format("DD/MM/YYYY, HH:mm") : "-"}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </AppMainLayout>
  );
};

export default RTPDetails;
