import { Link } from "react-router-dom";
import { UseErrorLabel } from "../../../hooks/useErrorLabel";
import dayjs from "dayjs";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { amountFormatter } from "../../../constants"

export const renderRTPRequestsCells = (styles, t) => {
    return [
      {
        field: "id",
        headerName: t("ID"),
        flex: 0.3,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <Link to={`/app/requests/${row.id}`} className={styles.no_underline}>
            {row.id}
          </Link>
        ),
      },
      {
        field: "phoneNumber",
        headerName: t("Client_phone_number"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>{row.phoneNumber}</span>
        ),
      },
      {
        field: "source",
        headerName: t("RTP_source"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>{row.source}</span>
        ),
      },
      {
        field: "paymentDescription",
        headerName: t("Payment_description"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>{row.paymentDescription}</span>
        ),
      },
      {
        field: "amount",
        headerName: t("Payment_amount"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <div className={styles.no_color}>
            {amountFormatter(row.amount)}
            <span className={styles.uppercase}>{row.currency}</span>
          </div>
        ),
      },
      {
        field: "createdAt",
        headerName: t("Request_creation_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>
            {dayjs(row.createdAt).format("DD/MM/YYYY, HH:mm")}
          </span>
        ),
      },
      {
        field: "status",
        headerName: t("Request_status"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => UseErrorLabel(row.status, t),
      },
      {
        field: "expiresAt",
        headerName: t("Request_expiry_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>
            {row.expiresAt ? dayjs(row.expiresAt).format("DD/MM/YYYY, HH:mm") : "-"}
          </span>
        ),
      },
    ];
  };

  export const renderRTPImportCells = (styles, t, setShowDetailsModal) => {
    return [
      {
        field: "id",
        headerName: t("ID"),
        flex: 0.3,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <div className={styles.no_color}  onClick={() => setShowDetailsModal({ state: true, detailsText: row.errorDetails })}>
            {row.id}
          </div>
        ),
      },
      {
        field: "fileName",
        headerName: t("File_name"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>{row.fileName}</span>
        ),
      },
      {
        field: "importedBy",
        headerName: t("Import_by"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>{row.importedBy}</span>
        ),
      },
      {
        field: "processingTime",
        headerName: t("Processing_time"),
        flex: 0.3,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <div className={styles.no_color}>
            <span className={styles.uppercase}>{row.processingTime}</span>
          </div>
        ),
      },
      {
        field: "importDate",
        headerName: t("Import_date"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => (
          <span className={styles.no_color}>
            {dayjs(row.importDate).format("DD/MM/YYYY, HH:mm")}
          </span>
        ),
      },
      {
        field: "totalRecords",
        headerName: t("Total_records"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <div className={styles.no_color}>
            <span className={styles.uppercase}>{row.totalRecords}</span>
          </div>
        ),
      },
      {
        field: "successfulRequests",
        headerName: t("Successful_requests"),
        flex: 0.3,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <div className={styles.no_color}>
            <span className={styles.uppercase}>{row.successfulRequests}</span>
          </div>
        ),
      },
      {
        field: "errorCount",
        headerName: t("Error_count"),
        flex: 0.3,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <div className={styles.no_color}>
            <span className={styles.uppercase}>{row.errorCount}</span>
          </div>
        ),
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => UseErrorLabel(row.status, t),
      },
      {
        field: "errorDetails",
        headerName: t("Error_details"),
        flex: 0.3,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => (
          <div className={styles.no_color} onClick={() => setShowDetailsModal({ state: true, detailsText: row.errorDetails })}>
            <span className={styles.uppercase}>
              <RemoveRedEyeIcon />
            </span>
          </div>
        ),
      },
    ];
  };