import { ListDottIcon } from "../svg-icons/icon-list-dott";
import { capitalize } from "../constants";

const renderErorLabel = (errLabel, clName, t, postfix) => {
  return (
    <div className={`error-label ${clName}`}>
      <ListDottIcon />
      {t(capitalize(`${errLabel}${postfix}`))}
    </div>
  );
};

export const UseErrorLabel = (err, t, postfix = "") => {
  let errLabel;

  switch (err) {
    case "error":
    case "expired":
    case "closed_shift":
    case "denied":
      errLabel = renderErorLabel(err, "red", t, postfix);
      break;

    case "deleted":
    case "partial_revert":
    case "unattached":
    case "offline":
      errLabel = renderErorLabel(err, "gray", t, postfix);
      break;

    case "reverted":
    case "revert":
    case "rejected":
    case "suspended":
      errLabel = renderErorLabel(err, "dark-gray", t, postfix);
      break;

    case "active":
    case "activated":
    case "in_progress":
      errLabel = renderErorLabel(err, "blue", t, postfix);
      break;

    case "attached":
      errLabel = renderErorLabel(err, "orange", t, postfix);
      break;

    case "completed":
    case "success":
    case "opened_shift":
    case "online":
    case "paid":
      errLabel = renderErorLabel(err, "green", t, postfix);
      break;

    case "in_process":
    case "pending":
    case "created":
      errLabel = renderErorLabel(err, "yellow", t, postfix);
      break;

    case "discussion":
      errLabel = renderErorLabel(err, "yellow", t, postfix);
      break;

    case "approved":
      errLabel = renderErorLabel(err, "dark-gray", t, postfix);
      break;

    case "closed":
      errLabel = renderErorLabel(err, "gray", t, postfix);
      break;

    case "canceled":
      errLabel = renderErorLabel(err, "gray", t, postfix);
      break;

    case "bank":
      errLabel = renderErorLabel(err, "assigned-orange", t, postfix);
      break;

    case "merchant":
      errLabel = renderErorLabel(err, "assigned-dop", t, postfix);
      break;

    default:
      errLabel = "";
      break;
  }

  return errLabel;
};
