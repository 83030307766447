/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { apiRequest } from "../../api/services/Api";
import { useTranslation } from "react-i18next";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import classNames from "classnames";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { getAccessToken } from "../../api/selectors/tokenSelector";
import dayjs from "dayjs";
import styles from "./terminal-details.module.scss";
import { enqueueSnackbar } from "notistack";

const TerminalDetails = () => {
  const { t } = useTranslation();
  const { terminalId } = useParams();
  const defaultTrxInfo = {
    id: null,
    terminalId: null,
    merchantId: null,
    storeId: null,
    storeName: null,
    mccCode: null,
    state: null,
    comment: null,
    whenCreated: null,
    merchantIdno: null,
    type: null,
  };

  const [isFetching, setIsFetching] = useState(true);
  const [terminalInfo, setTerminalInfo] = useState({ ...defaultTrxInfo });
  const [redirect, setRedirect] = useState(false);

  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    const fetchTerminalDetails = async () => {
      setIsFetching(true);
      try {
        const { status, response } = await apiRequest(
          "get",
          `/cap/api/v1/pos/${terminalId}`,
          null
        );

        if (status === 200 || status === 201) {
          const { data } = response;
          setTerminalInfo(data);
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      } catch (error) {
        console.error("Failed to fetch transaction details:", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchTerminalDetails();
  }, [accessToken, terminalId]);

  const navBarTitle = `#${terminalId}`;

  const breadcrumbs = {
    step1Label: t("Terminals"),
    step1Url: "/app/terminals",
    step2Label: `#${terminalId}`,
  };

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }}>
      <div>
        {redirect && <Redirect to={"/app/terminals/"} />}
        <div
          className={classNames({
            // [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <div className={styles.terminal_content}>
              <div className={styles.info_block}>
                <div className={styles.info_block_title}>
                  <span>{t("Terminal_details")}</span>
                </div>
                <div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Date")}</label>
                    <div className={styles.block_value}>
                      {dayjs(terminalInfo.whenCreated).format(
                        "DD/MM/YYYY, HH:mm"
                      )}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Terminal_id")}</label>
                    <div className={styles.block_value}>{terminalInfo.id}</div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Terminal_type")}</label>
                    <div className={styles.block_value}>
                      {terminalInfo.type === "mia_pos"
                        ? "Mia POS"
                        : terminalInfo.type === "rtp"
                        ? "RTP"
                        : "Ecomm"}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Store")}</label>
                    <div className={styles.block_value}>
                      <Link
                        to={`/app/stores/${terminalInfo.storeId}`}
                        className={styles.no_underline}
                      >
                        {terminalInfo.storeName}
                      </Link>
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Terminal_name")}</label>
                    <div className={styles.block_value}>
                      {terminalInfo.terminalId}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Merchant_id")}</label>
                    <div className={styles.block_value}>
                      {/* <Link
                        to={`/app/merchants/${terminalInfo.merchantId}`}
                        className={styles.no_underline}
                      > */}
                      {terminalInfo.merchantId}
                      {/* </Link> */}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("MCC")}</label>
                    <div className={styles.block_value}>
                      {terminalInfo.mccCode}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Status")}</label>
                    <div className={styles.block_value}>
                      {UseErrorLabel(terminalInfo.state, t)}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Comment")}</label>
                    <div className={styles.block_value}>
                      {terminalInfo.comment || " - "}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.info_block}>
                <div className={styles.info_block_title}>
                  <span>{t("Terminal_details_activation")}</span>
                </div>
                <div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Merchant_idno")}</label>
                    <div className={styles.block_value}>
                      {terminalInfo.merchantIdno}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Terminal_id")}</label>
                    <div className={styles.block_value}>
                      {terminalInfo.terminalId}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppMainLayout>
  );
};

export default TerminalDetails;
