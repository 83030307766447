import React, { ChangeEvent, useEffect, useState, useRef, useCallback } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";

import { useTranslation } from "react-i18next";

import CircularProgress from "@mui/material/CircularProgress";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from "classnames";
import CustomButton from "../../infrastructure/components/Button";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { PlusIcon } from "../../svg-icons/icon-plus";
import { AttachFileClipIcon } from "../../svg-icons/icon-attach-file-clip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";
import { apiRequest } from "../../api/services/Api";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../infrastructure/components/Pagination";
import { setPageSize } from "../../api/actionCreators/mainActionCreator";
import { getRTPPageSize } from "../../api/selectors/mainSelector";
import { AttachFileIcon } from "../../svg-icons/icon-attach-file";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useDebounce from "../../hooks/useDebounce";

import {
  pages,
  filtersRTP,
  capitalize,
  VALIDATION,
  isFileValid,
  RTP_TABS,
} from "../../constants";
import { renderRTPRequestsCells, renderRTPImportCells } from "./constants"

import styles from "./rtp.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className={styles.rtp_tables_container}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const RTP = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("all");
  const [totalCount, setTotalCount] = useState(0);
  const [invalidFields, setInvalidFields] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = useSelector(getRTPPageSize);

  const [showAddNewRTPModal, setShowAddNewRTPModal] = useState(false);
  const [newRTPData, setNewRTPData] = useState({
    phoneNumber: "",
    amount: "",
    paymentDescription: "",
  });
  const [isAdding, setIsAdding] = useState(false);
  const [selectedTab, setSelectedTab] = useState(RTP_TABS.REQUESTS);
  const [showImportModal, setShowImportModal] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [file, setFile] = useState({
    src: "",
    name: ""
  });
  const fileInputRef = useRef(null);

  const [posTerminals, setPosTerminals] = useState([]);
  const [selectedPosId, setSelectedPosId] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const [showErrorContainer, setShowErrorContainer] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState({ state: false, detailsText: "" });
  const [fullFile, setFullFile] = useState(null);
  // 


  const fetchRTP = useCallback(async (selectedTab) => {
    setIsFetching(true);


    try {
      const f = filter === "all" ? "" : filter;
      const offset = currentPage * pageSize;
      const isRequestsTab = selectedTab === RTP_TABS.REQUESTS
      const apiUrl = isRequestsTab ?
        `/cap/api/v1/rtp?offset=${offset}&count=${pageSize}&filter=${searchText}&rtpStatus=${f}` :
        `/cap/api/v1/rtp/import/history?offset=${offset}&count=${pageSize}`

      const { status, response } = await apiRequest(
        "get",
        apiUrl,
        null
      );

      if (status === 200 || status === 201) {

        let mappedRequests;
        if (isRequestsTab) {
          const {
            requestsList,
            pagination: { totalCount },
          } = response.data;

          mappedRequests = requestsList.map((item) => ({
            id: item.id,
            phoneNumber: item.phoneNumber,
            paymentDescription: item.description,
            amount: item.amount,
            currency: item.currency,
            createdAt: item.whenCreated,
            status: item.status,
            expiresAt: item.expireDate,
            source: item.source,
          }));

          setTotalCount(totalCount);
        } else {
          const {
            imports,
            pagination: { totalCount },
          } = response.data;

          mappedRequests = imports.map((item) => ({
            id: item.id,
            fileName: item.fileName,
            importedBy: item.importedBy,
            importDate: item.importDate,
            processingTime: item.processingTime,
            totalRecords: item.totalRecords,
            successfulRequests: item.successfulRequests,
            errorCount: item.errorCount,
            status: item.status,
            errorDetails: item.errorDetails
          }));

          setTotalCount(totalCount);
        }


        setRows(mappedRequests || []);

      } else {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setRows([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Failed to fetch RTP data:", error);
      enqueueSnackbar(t("Error_500"), { variant: "error" });
      setRows([]);
      setTotalCount(0);
    } finally {
      setIsFetching(false);
    }
  }, [currentPage, pageSize, filter, searchText, enqueueSnackbar, t]);

  useDebounce(
    () => {
      fetchRTP(selectedTab);
    },
    [
      searchText,
      currentPage,
      pageSize,
      filter,
      selectedTab,
    ],
    500
  );


  useEffect(() => {
    setSearchText("")
  }, [selectedTab]);

  const fetchPosTerminals = async () => {
    try {
      const { status, response } = await apiRequest(
        "get",
        "/cap/api/v1/pos/brief?terminalType=rtp",
        null
      );
      if (status === 200) {
        setPosTerminals(response.data || []);
      } else {
        enqueueSnackbar(t("Error_loading_pos_terminals"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch POS terminals:", error);
      enqueueSnackbar(t("Error_loading_pos_terminals"), { variant: "error" });
    }
  };

  useEffect(() => {
    if (showAddNewRTPModal || showImportModal) {
      fetchPosTerminals();
    }
  }, [showAddNewRTPModal, showImportModal]);

  const validateInput = () => {
    const result = [];

    VALIDATION.ADD_RTP_FORM.forEach((field) => {
      if (field === "phoneNumber" && newRTPData["phoneNumber"]) {
        const phone = newRTPData &&
          newRTPData.phoneNumber
            .replace(/\-/g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/\ /g, "")
            .replace(/\+/g, "").slice(3);

        if (
          !newRTPData["phoneNumber"] ||
          newRTPData["phoneNumber"].length === 0 ||
          phone.length !== 8
        ) {
          result.push("phoneNumber");
        }
      } else {
        !newRTPData[field] && result.push(field);
      }
    });
    return result.length > 0 ? result : null;
  };

  const handleAddNewRTP = async () => {


    const invalidFields = validateInput();
    setInvalidFields(invalidFields);
    console.log("CREATE", invalidFields);
    if (invalidFields) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsFetching(false);
      return;
    }

    const formattedPhoneNumber = newRTPData.phoneNumber
      .replace(/[^\d]/g, "")
      .slice(-8);

    const requestData = {
      phoneNumber: formattedPhoneNumber,
      amount: Number(newRTPData.amount),
      ccy: "MDL",
      description: newRTPData.paymentDescription,
      posId: Number(selectedPosId),
    };
    setIsAdding(true);
    try {
      const { status, response } = await apiRequest(
        "post",
        "/cap/api/v1/rtp",
        requestData
      );

      if (status === 200) {
        if (response.data.result === "success") {
          enqueueSnackbar(t("Request_successfully_added"), { variant: "success" });
          setShowAddNewRTPModal(false);
          setSelectedTab(RTP_TABS.REQUESTS)
          setNewRTPData({ phoneNumber: "", amount: "", paymentDescription: "" });
          setSelectedPosId("");
          fetchRTP(selectedTab);
        } else if (response.data.result === "terminal_not_found") {
          enqueueSnackbar(t("Terminal_not_found"), { variant: "error" });
        } else if (response.data.result === "terminal_not_active") {
          enqueueSnackbar(t("Terminal_not_active"), { variant: "error" });
        } else if (response.data.result === "terminal_shift_not_active") {
          enqueueSnackbar(t("Terminal_shift_not_active"), { variant: "error" });
        } else if (response.data.result === "error") {
          enqueueSnackbar(t("Unknow_error"), { variant: "error" });
        }

      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      } else {
        enqueueSnackbar(t("Error_unknown"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to add new RTP request:", error);
      enqueueSnackbar(t("Error_adding_rtp"), { variant: "error" });
    } finally {
      setIsAdding(false);
    }
  };

  const handleCloseModal = () => {
    setShowAddNewRTPModal(false);
    setShowImportModal(false);
    setIsImporting(false)
    handleCloseImportModal()
    setShowDetailsModal({ state: false, detailsText: "" });
    setFile({
      name: "",
      src: "",
    });
    setFullFile("");
    setCopied(false);
    setErrorResponse("");
    setShowErrorContainer(false)
    setNewRTPData({ phoneNumber: "", amount: "", paymentDescription: "" });
    setSelectedPosId("");
    setInvalidFields([])
  };

  const isFormValid = () => {
    const { phoneNumber, amount, paymentDescription } = newRTPData;
    return (
      phoneNumber.trim() !== "" &&
      amount.trim() !== "" &&
      paymentDescription.trim() !== "" &&
      selectedPosId !== ""
    );
  };

  const removeError = (stateName) => {
    if (invalidFields && invalidFields.length > 0) {
      const fIndex =
        invalidFields && invalidFields.findIndex((e) => e === stateName);
      const errorListFields = [
        ...invalidFields?.slice(0, fIndex),
        ...invalidFields?.slice(fIndex + 1),
      ];

      setInvalidFields(errorListFields || []);
    }
  };

  const renderAddNewRTPModal = () => {
    return (
      <Dialog
        open={showAddNewRTPModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="add-new-rtp-dialog-description"
      >
        <DialogTitle>{t("New_rtp")}</DialogTitle>
        <DialogContent sx={{ overflowY: "visible" }}>
          <div className={styles.info_block_input_container}>
            <label htmlFor="phoneNumber" className={styles.label}>
              {t("Client_phone_number")}
            </label>
            <FormControl fullWidth>
              {/* <InputMask
                mask="+373 99 999 999"
                value={newRTPData.phoneNumber}
                onChange={(e) =>
                  setNewRTPData({ ...newRTPData, phoneNumber: e.target.value })
                }
              >
                {(inputProps) => (
                  <OutlinedInput
                    {...inputProps}
                    placeholder={t("Client_phone_number")}
                  />
                )}
              </InputMask>*/}
              <InputMask
                mask="(+373) 99-999-999"
                value={newRTPData.phoneNumber}
                onChange={(e) => {
                  setNewRTPData({ ...newRTPData, phoneNumber: e.target.value })
                  removeError("phoneNumber");
                }}
                maskChar={""}
              >
                {() => (
                  <TextField
                    fullWidth
                    id="phone"
                    label={t("Client_phone_number")}
                    variant="outlined"
                    helperText={
                      invalidFields?.length > 0 &&
                      invalidFields.findIndex(
                        (i) => i === "phoneNumber"
                      ) > -1 &&
                      t("Required_field")
                    }
                    error={
                      invalidFields?.length > 0 &&
                      invalidFields.findIndex(
                        (i) => i === "phoneNumber"
                      ) > -1
                    }
                    inputProps={{
                      tabIndex: 2,
                    }}
                  />
                )}
              </InputMask>
            </FormControl>
          </div>

          <div className={styles.info_block_input_container}>
            <label htmlFor="amount" className={styles.label}>
              {t("Amount_request")}
            </label>
            <FormControl fullWidth margin="normal">
              <TextField
                placeholder={t("Amount_request")}
                type="number"
                value={newRTPData.amount}
                onChange={(e) =>
                  setNewRTPData({ ...newRTPData, amount: e.target.value })
                }
                required
              />
            </FormControl>
          </div>

          <div className={styles.info_block_input_container}>
            <label htmlFor="paymentDescription" className={styles.label}>
              {t("Payment_description")}
            </label>
            <FormControl fullWidth margin="normal">
              <TextField
                placeholder={t("Payment_description")}
                value={newRTPData.paymentDescription}
                onChange={(e) =>
                  setNewRTPData({
                    ...newRTPData,
                    paymentDescription: e.target.value,
                  })
                }
                required
                multiline
                rows={4}
              />
            </FormControl>
          </div>

          <div className={styles.info_block_input_container}>
            <label htmlFor="posId" className={styles.label}>
              {t("Select_terminal")}
            </label>
            <FormControl fullWidth margin="normal">
              <InputLabel id="pos-select-label">{t("Select_terminal")}</InputLabel>
              <Select
                labelId="pos-select-label"
                id="pos-select"
                value={selectedPosId}
                onChange={(e) => setSelectedPosId(e.target.value)}
                label={t("Select_terminal")}
              >
                {posTerminals.map((terminal) => (
                  <MenuItem key={terminal.id} value={terminal.id}>
                    {terminal.terminalCode} - {terminal.terminalType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleAddNewRTP}
            variant="primary"
            disabled={!isFormValid() || isAdding}
            label={t("Send")}
          >
            {isAdding && (
              <CircularProgress className="circular-progress" size={18} />
            )}
          </CustomButton>
          <Button onClick={handleCloseModal} variant="text">
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const onImportFile = async () => {
    setIsImporting(true)

    const formData = new FormData();
    formData.append('file', fullFile);
    formData.append('posId', selectedPosId);

    try {
      const { status, response } = await apiRequest(
        "post",
        "/cap/api/v1/rtp/import",
        formData
      );

      if (status === 200) {
        if (response.data.status === "in_progress") {
          enqueueSnackbar(t("Import_file_in_process"), { variant: "success" });
          handleCloseModal();
          setSelectedTab(RTP_TABS.IMPORT)
          fetchRTP(selectedTab);
        } else if (response.data.status === "terminal_not_found") {
          enqueueSnackbar(t("Terminal_not_found"), { variant: "error" });
        } else if (response.data.status === "terminal_not_active") {
          enqueueSnackbar(t("Terminal_not_active"), { variant: "error" });
        } else if (response.data.status === "terminal_shift_not_active") {
          enqueueSnackbar(t("Terminal_shift_not_active"), { variant: "error" });
        } else if (response.data.status === "wrong_file_type") {
          enqueueSnackbar(t("Wrong_file_type"), { variant: "error" });
        } else if (response.data.status === "invalid_records") {
          setErrorResponse(JSON.stringify(response.data.invalidRecords));
          setShowErrorContainer(true)
          enqueueSnackbar(t("Invalid_records"), { variant: "error" });
        } else if (response.data.status === "error") {
          enqueueSnackbar(t("Unknow_error"), { variant: "error" });
        }

      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      } else {
        enqueueSnackbar(t("Error_unknown"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to add new RTP request:", error);
      enqueueSnackbar(t("Unknow_error"), { variant: "error" });
    } finally {
      setIsImporting(false);
    }
  }

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {

    if (!e.target.files) {
      return;
    }

    const fileUploaded = e.target.files[0];
    setFullFile(fileUploaded)
    let isValid = isFileValid(fileUploaded, t);
    if (!isValid) {
      return;
    }

    const getBase64 = (fileUploaded, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(fileUploaded);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    getBase64(fileUploaded, (result) => {
      setFile({
        name: fileUploaded.name,
        src: result,
      });
    });
  };

  const renderImportRTPModal = () => {
    return (
      <Dialog
        open={showImportModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="add-new-rtp-dialog-description"
      >
        <DialogTitle>{t("New_rtp")}</DialogTitle>
        <DialogContent>

          <div className={styles.info_block_input_container}>
            <label htmlFor="paymentDescription" className={styles.label}>
              {t("Select_file")}
            </label>
            <Button
              component="label"
              disabled={!!file.name}
              className={styles.attach_logo}
            >
              {t("Select_file")}
              <AttachFileIcon />
              <input
                type="file"
                hidden
                ref={fileInputRef}
                disabled={!!file.name}
                onChange={(e) => handleFileUpload(e)}
              />
            </Button>
          </div>

          <div className={styles.info_block_input_container}>
            <label htmlFor="posId" className={styles.label}>
              {t("Select_terminal")}
            </label>
            <FormControl fullWidth margin="normal">
              <InputLabel id="pos-select-label">{t("Select_terminal")}</InputLabel>
              <Select
                labelId="pos-select-label"
                id="pos-select"
                value={selectedPosId}
                onChange={(e) => setSelectedPosId(e.target.value)}
                label={t("Select_terminal")}
              >
                {posTerminals.map((terminal) => (
                  <MenuItem key={terminal.id} value={terminal.id}>
                    {terminal.terminalCode} - {terminal.terminalType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        {showErrorContainer && copyErrorContainer(errorResponse)}
        {file.src && (
          <div
            className={classNames({
              [`${styles.settings_block_description}`]: true,
              [`${styles.additional_padding}`]: true,
              [`${styles.logo_background}`]: true,
            })}
          >
            <div>
              {isImporting && (
                <CircularProgress className="circular-progress" size={18} />
              )}
              {file.name}
            </div>

            {file.src && (
              <div className="image-info">
                <CloseIcon
                  onClick={() => {
                    setFile({ src: "", name: "" });
                    setFullFile("");
                    setInvalidFields([])
                    setShowErrorContainer(false)
                    fileInputRef.current.value = null;
                  }}
                  sx={{ color: "#606060", cursor: "pointer" }}
                />
              </div>
            )}
          </div>
        )}

        <DialogActions>
          <CustomButton
            onClick={() => onImportFile()}
            variant="primary"
            disabled={!file.name || isImporting || !selectedPosId || showErrorContainer}
            label={t("Import")}
          >
            {isImporting && (
              <CircularProgress className="circular-progress" size={18} />
            )}
          </CustomButton>
          <Button onClick={handleCloseModal} variant="text">
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }


  const renderDetailsModal = () => {
    return (
      <Dialog
        open={showDetailsModal.state}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="add-new-rtp-dialog-description"
      >
        <DialogTitle>{t("Details")}</DialogTitle>
        <DialogContent>

          <div style={{ minWidth: "400px" }}>
            {showDetailsModal.detailsText ? copyErrorContainer(showDetailsModal.detailsText) : t("No_details")}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal} variant="text">
            {t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const handleCloseImportModal = () => {
    setShowImportModal(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setIsFetching(true);
    setCurrentPage(0);
    dispatch(setPageSize(pages.RTP_PAGE, size));
  };

  const renderFilterItems = () => {
    return filtersRTP.map((filterName) => (
      <button
        key={filterName}
        className={classNames({
          [styles.filter_button]: true,
          [styles.filter_button_active]: filter === filterName,
        })}
        onClick={() => {
          setCurrentPage(0);
          setFilter(filterName);
        }}
      >
        {filterName === "all" ? t("All") : t(`${capitalize(filterName)}`)}
      </button>
    ));
  };



  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    setCurrentPage(0);
  };

  const copyErrorContainer = (errorData) => {
    return (
      <div className={styles.error_container} >
        <CopyToClipboard
          text={errorData}
          onCopy={() => setCopied(true)}
        >
          <Button
            color="primary"
            disabled={copied}
            variant="contained"
          >
            {!copied ? "Copy" : "Copied"}
          </Button>
        </CopyToClipboard>
        <div>
          {errorData}
        </div>
      </div>
    )
  }

  const commonTable = () => {
    return (
      <DataTableComponent
        checkboxSelection={false}
        columns={selectedTab === RTP_TABS.REQUESTS ? renderRTPRequestsCells(styles, t) : renderRTPImportCells(styles, t, setShowDetailsModal)}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.terminal_table}
        rows={rows}
      />
    )
  }
  return (
    <AppMainLayout navBarTitle={t("Request_to_pay")}>
      {renderAddNewRTPModal()}
      {renderImportRTPModal()}
      {renderDetailsModal()}
      <div className={styles.customer_page_bottom_container}>
        {selectedTab !== RTP_TABS.IMPORT && <FormControl fullWidth >
          <InputLabel htmlFor="search-input">{t("Search")}</InputLabel>
          <OutlinedInput
            id="search-input"
            startAdornment={<SearchIcon />}
            label={t("Search")}
            value={searchText}

            onChange={(e) => {
              setCurrentPage(0);
              setSearchText(e.target.value);
            }}
          />
        </FormControl>}
        <div className={styles.button_group}>
          <CustomButton
            variant="primary"
            icon={<PlusIcon />}
            label={t("New_rtp")}
            disabled={isFetching}
            onClick={() => setShowAddNewRTPModal(true)}
            className={styles.add_new_rtp}
          />
          <CustomButton
            variant="primary"
            icon={<AttachFileClipIcon />}
            label={t("Importing_file")}
            disabled={isFetching}
            onClick={() => setShowImportModal(true)}
            className={styles.add_new_rtp}
          />
        </div>
      </div>
      {/* {selectedTab} */}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ marginBottom: "20px" }}>
          <Tabs value={selectedTab} onChange={handleChange} className={styles.tab_name} aria-label="basic tabs example">
            <Tab label={t("Request_to_pay")} value={RTP_TABS.REQUESTS} />
            <Tab label={t("Imports")} value={RTP_TABS.IMPORT} />
          </Tabs>
        </Box>
        <CustomTabPanel value={selectedTab} index={RTP_TABS.REQUESTS}>
          <div className={styles.filter_container}>{renderFilterItems()}</div>
          {commonTable()}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={RTP_TABS.IMPORT}>
          {commonTable()}
        </CustomTabPanel>
      </Box>

      {!isFetching && (
        <Pagination
          onClick={paginationClick}
          onChangePageSize={pageSizeClick}
          total={totalCount}
          pageIndex={currentPage}
          pageSize={pageSize}
        />
      )}
    </AppMainLayout>
  );
};

export default RTP;
